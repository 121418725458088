import {
  Backdrop,
  Box,
  Modal,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { togglePaymentModal, closePaymentModal } from "../../redux/paymentModalSlice";
import { loadStripe } from "@stripe/stripe-js";
import { API_KEY, API_KEY_TEST, userInfoServerBaseUrl } from "../../env";
import { authSliceType, fetchRemoteBalance } from "../../redux/authSlice";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";

export const PaymentModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const auth = useSelector<RootState, authSliceType>((state) => state.auth);
  const { show, paymentAmount } = useSelector((state: RootState) => state.paymentModal);

  const [stripePromise, setStripePromise] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchClientSecret = useCallback(async () => {
    console.log("FETCHING CLIENT SECRET");
    setError(null);
    return fetch(`${userInfoServerBaseUrl}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: paymentAmount,
        auth: auth,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Received client secret:", data.clientSecret);
        return data.clientSecret;
      })
      .catch((err) => {
        console.error("Error fetching client secret:", err);
        setError("Failed to initialize checkout. Please try again.");
        throw err;
      })
      .finally(() => null);
  }, [auth, paymentAmount]);

  const handleCheckoutComplete = useCallback(async () => {
    setTimeout(() => {
      dispatch(fetchRemoteBalance());
    }, 3000);
  }, [dispatch]);

  const options = { fetchClientSecret, onComplete: handleCheckoutComplete };

  useEffect(() => {
    if (show && !stripePromise) {
      console.log("Loading stripe promise...");
      setStripePromise(loadStripe(API_KEY_TEST));
    }
  }, [show, stripePromise]);

  return (
    <Modal
      open={show}
      onClose={() => dispatch(closePaymentModal())}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backdropFilter: "blur(3px)",
          backgroundColor: "rgba(0, 0, 0, 0.65)",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1301,
          width: "500px",
          maxWidth: "80vw",
          minHeight: "400px",
          overflowY: "auto",
          outline: "none",
          background: "white",
          padding: 3,
          borderRadius: 2,
        }}
      >
        {error && <Typography>{error}</Typography>}
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout className={"max-h-[90vh]"} />
        </EmbeddedCheckoutProvider>
      </Box>
    </Modal>
  );
};
