import { useContext, useEffect, useState } from "react";
import React from "react";
import ClipCard from "./components/ClipPage/ClipCard";
import UploadCard from "./components/ClipPage/UploadCard";
import {
  Box,
  Button,
  Card,
  Fab,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { userInfoServerBaseUrl } from "./env";

import { useSelector, useDispatch } from "react-redux";
import { clipPageSliceType, setQuality, updateResultImg } from "./redux/clipPageSlice";
import { AppDispatch, RootState } from "./redux/store";
import { addDstCropArr } from "./redux/clipPageSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import NavBar from "./components/NavBar";
import ImageSelectMain from "./components/ClipPage/ImageSelectMain";
import SelectedImage from "./components/ClipPage/SelectedImage";
import ResultModal from "./components/ClipPage/ResultModal";
import FaceTypeIndicator from "./components/ClipPage/FaceTypeIndicator";
import { authSliceType, fetchRemoteBalance } from "./redux/authSlice";
import Footer from "./components/Footer";
import { DARK_THEME } from ".";

const donorFaceLink =
  "https://firebasestorage.googleapis.com/v0/b/clipfake.appspot.com/o/Donor_v9%20(small).mp4?alt=media&token=d060e496-57f1-489d-a32b-31123f3444ab";
const recipientFaceLink =
  "https://firebasestorage.googleapis.com/v0/b/clipfake.appspot.com/o/Recipient_v6%20(small).mp4?alt=media&token=1a10611f-373f-413c-bfab-674825cf1964";

const ClipPage = () => {
  const DSTLENGTH = 60;
  const SRCLENGTH = 120;

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [fakeIsValid, setFakeIsValid] = useState(false);
  const [showError, setShowError] = useState(null);
  const [sessionStarted, setSessionStarted] = useState(false);

  const auth = useSelector<RootState, authSliceType>((state) => state.auth);
  const [srcImgTabSelection, setSrcImgTabSelection] = useState(
    auth?.jwt ? "upload" : "presets"
  );

  const quality = useSelector<RootState, string>((state) => state.clipPage.quality);
  const dstCropData = useSelector<RootState, clipPageSliceType["dstCropArr"]>(
    (state) => state.clipPage.dstCropArr
  );
  const srcImg = useSelector<RootState, clipPageSliceType["srcImg"]>(
    (state) => state.clipPage.srcImg
  );
  const dstImg = useSelector<RootState, clipPageSliceType["dstImg"]>(
    (state) => state.clipPage.dstImg
  );
  const resultImg = useSelector<RootState, clipPageSliceType["resultImg"]>(
    (state) => state.clipPage.resultImg
  );
  const [dstLengthLeft, setDstLengthLeft] = useState<number>(SRCLENGTH);

  // Check state for validity every interval to activate button
  useEffect(() => {
    const intervalId = setInterval(() => {
      setFakeIsValid(checkFakeIsValid());
    }, 500);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [dstCropData, srcImg, dstImg]);

  const checkFakeIsValid = () => {
    const checkDstArray = () => {
      let ytGood = false;
      let timeGood = false;
      for (let i = 0; i < dstCropData.length; i++) {
        if (dstCropData[i].fullYoutube) {
          ytGood = true;
        }
        if (dstCropData[i].timeArr[2] - dstCropData[i].timeArr[0] > 10 && ytGood) {
          timeGood = true;
        } else {
          ytGood = false;
        }
      }
      if (ytGood && timeGood) return true;
      return false;
    };

    if ((checkDstArray() || dstImg) && srcImg) {
      return true;
    }
    return false;
  };

  const handleRequestSesh = (event: any) => {
    event.preventDefault();
    console.log("Requesting sesh...");

    setSessionStarted(true);

    interface CustomResponse extends Response {
      seshId: string | null;
      error: string | null;
    }

    const sendRequest = async () => {
      if (dstCropData[0]?.fullYoutube) {
        const response = (await fetch(`${userInfoServerBaseUrl}/startSesh`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            auth: auth,
            dst: dstCropData,
            src: srcImg,
            quality: quality,
          }),
        })) as CustomResponse;

        if (response.ok) {
          const res = await response.json();
          console.log("RESPONSE IS " + JSON.stringify(res));
          if (!res.error) {
            console.log("sesh id is " + res.seshId);
            dispatch(fetchRemoteBalance());
            navigate(`/listSessions`);
          } else {
            setShowError(res.error);
          }
        } else {
          const res = await response.text();
          console.log("Couldn't connect to user info server " + res);
        }
      } else if (dstImg) {
        setTimeout(() => setSessionStarted(false), 3000);
        const newImg = [...resultImg];
        newImg.push({ timeLeft: 30, dstImg: dstImg });
        dispatch(updateResultImg(newImg));
        dispatch(fetchRemoteBalance());
      }
    };

    sendRequest();
  };

  const handleAddDstVideo = () => {
    const defaultObj = {
      fullYoutube: "",
      youtubeId: "",
      timeArr: [0, 1, 20],
    };
    dispatch(addDstCropArr(defaultObj));
  };

  const handleTglBtnAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment !== null) {
      dispatch(setQuality(newAlignment));
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div className="w-full mb-4">
        <NavBar signinInProgressRef={null} />
      </div>
      <h1>{showError}</h1>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FaceTypeIndicator text="Recipient Face" img={recipientFaceLink} />
        {dstCropData.map((cropObj, ind) =>
          cropObj.fullYoutube ? (
            <ClipCard cropObj={cropObj} ind={ind} maxLength={dstLengthLeft} key={ind} />
          ) : dstImg ? (
            <SelectedImage srcOrDst="dst" />
          ) : (
            <UploadCard
              ind={ind}
              key={ind}
              callback={null}
              handleAlignmentChange={null}
            />
          )
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FaceTypeIndicator text="Donor Face" img={donorFaceLink} />
        {srcImg ? (
          <SelectedImage srcOrDst="src" />
        ) : (
          <Box className="w-full flex flex-col items-center content-center">
            <Paper
              className="flex flex-col place-items-center"
              sx={{
                transition: "all 0.5s ease",
                "@media (orientation: portrait)": {
                  minWidth: "95vw",
                  maxWidth: "95vw",
                },
              }}
            >
              <ImageSelectMain
                srcOrDst={"src"}
                callback={null}
                imgTabSelection={srcImgTabSelection}
                setImgTabSelection={setSrcImgTabSelection}
              />
            </Paper>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          mb: 3,
        }}
      >
        {dstCropData[0].fullYoutube && (
          <div>
            <ToggleButtonGroup
              value={quality}
              sx={{ mt: 6, mb: 1 }}
              size="small"
              exclusive
              onChange={handleTglBtnAlignment}
            >
              <ToggleButton value="480p">480p</ToggleButton>
              <ToggleButton value="720p">720p</ToggleButton>
              <ToggleButton value="1080p">1080p</ToggleButton>
            </ToggleButtonGroup>
          </div>
        )}
        <Tooltip
          arrow
          title={
            !auth?.jwt
              ? "Sign in to start."
              : !fakeIsValid
              ? "Select both faces to begin."
              : null
          }
        >
          <Box sx={{ display: "flex", mt: dstCropData[0].fullYoutube ? 1 : 6, mb: 3 }}>
            <Button
              onClick={handleRequestSesh}
              variant="contained"
              disabled={!fakeIsValid || !auth?.jwt || sessionStarted}
              size="large"
              endIcon={<FontAwesomeIcon icon={faPaperPlane} />}
              sx={{ width: 200, height: 100, borderRadius: 500, fontSize: 16 }}
            >
              START FAKE
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <Footer />
    </div>
  );
};

export default ClipPage;
